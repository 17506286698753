<template>
  <div class="modal-container">
    <img src="@/assets/images/IA/img_success2.png" alt="success">
    <p>비밀번호 재설정이 완료되었습니다.</p>
    <button class="btn-confirm" @click="btnConfirm">확인</button>
  </div>
</template>

<script>
export default {
  name: "RePasswordDialog",
  methods: {
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal', 0)
    },
  }
}
</script>

<style scoped>


.btn-confirm {
  width: 109px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
}


</style>
